<template>
  <router-view @touchstart="onTouch" />
</template>
<script setup>

    import axios from 'axios'

    const onTouch = () =>{
        console.log("touch");
        axios.get("/visit/heart").then(({data:res}) => {
            if (res.code == 200) {
                console.log("ok");
            } else {
                alert(res.msg);
            }
        })
    }

</script>
<style>
html,body{
    margin: 0;
    padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;

    margin: 0;
    padding: 0;

    width: 100vw;
    height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.page{
    width: 100%;
    height: 100%;
}

</style>
