<template>
<div class="page">
    <div class="wrapper">
        <div class="content">
            <div class="operate">
                <div class="title">发送资料</div>
                <div class="search">
                    <input v-model="searchKey" placeholder="搜索您要找的产品"><div class="btn" @click="syncData"></div>
                </div>
            </div>
            <div class="tab">
                <div class="item" :class="{ active : tab == 1 }" @click="changeTab(1)">全部</div>
                <div class="item" :class="{ active : tab == 2 }" @click="changeTab(2)">公司介绍</div>
                <div class="item" :class="{ active : tab == 3 }" @click="changeTab(3)">产品介绍</div>
            </div>
            <div class="content-scroll">
                <div class="list">
                    <div class="item" v-for="(item,index) in data" v-bind:key="index">
                        <div class="name">{{ item.name }}</div>
                        <div class="btn" @click="onFill(item.id)"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close" @click="close"></div>
    </div>
    <div class="fill" v-if="showFill">
        <div class="wrapper">
            <div class="fill-content">
                <div class="title">请填写发送邮箱</div>
                <div class="input-group">
                    <input v-model="sendParam.mail" placeholder="邮箱" type="email"/>
                </div>
                <div class="btn" @click="onSend">发送</div>
            </div>
            <div class="fill-close" @click="showFill = false"></div>
        </div>
    </div>
</div>
</template>

<script setup>
import {defineEmits, onBeforeMount, reactive, ref} from 'vue'
import axios from 'axios'

const emit = defineEmits(["close"]);
const close = ()=>{
    emit("close");
}

const showFill = ref(false);
const onFill = (contentId)=>{
    sendParam.contentId = contentId;
    showFill.value = true;
}
const tab = ref(1)
const changeTab = (value) => {
    tab.value = value;
}
const sendParam = reactive({
    mail:"",
    contentId:''
})
const onSend = () => {
    axios.post("/mail/send", {...sendParam}).then(({data}) => {
        if (data.code == 200) {
            showFill.value = false;
        } else if(data.code != -1) {
            alert(data.msg);
        }
    })
}

const searchKey = ref("");
const data = ref([]);
const syncData = () => {
    axios.get("/product/pdf/list",{params:{searchKey:searchKey.value}}).then(({data:res}) => {
        if (res.code == 200) {
            data.value = res.data;
        } else if(res.code != -1) {
            alert(res.msg);
        }
    })
}

onBeforeMount(() => {
    syncData();
})

</script>

<style scoped>
    .page{
        position: fixed;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wrapper{
        position: relative;
        width: 1606px;
        height: 1486px;
        border-radius: 60px;

        background: url("../assets/bg_fasong.png");
        background-size: cover;

        padding: 30px;
        box-sizing: border-box;
    }
    .wrapper .content{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 2px solid #267BDA;
        box-shadow: -9px 0px 7px 0px rgba(0,90,201,0.53);
        border-radius: 60px;

        padding: 110px 105px;
        box-sizing: border-box;
    }
    .content-scroll{
        height: 960px;
        overflow-y: scroll;
        margin-top: 40px;
    }
    .operate{
        display: flex;
        justify-content: space-between;
    }
    .title{
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #0053CF;
        line-height: 80px;
    }
    .search{
        width: 505px;
        height: 88px;
        background: rgba(0,0,0,0);
        border: 4px solid #0451C5;
        border-radius: 48px;
        padding: 0 50px;

        display: flex;
        align-items: center;
        position: relative;
    }
    .search input{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        outline:none;
        border: 0;
    }
    .search .btn{
        width: 92px;
        height: 92px;
        border-radius: 48px;
        background: #0451C5;
        position: absolute;
        right: -2px;
        top: -2px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search .btn:before{
        display: block;
        content: '';
        width: 46px;
        height: 46px;
        background: url("../assets/season.png") ;
        background-size: contain;
    }

    .tab{
        display: flex;
        margin-top: 100px;
    }
    .tab .item{
        width: 240px;
        height: 88px;
        margin-right: 25px;
        background: #E4EFFF;
        border-radius: 11px;

        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 88px;
        text-align: center;
    }
    .tab .item.active{
        background: #0053CF;
        color: #FFF;
    }
    .list{
        display: flex;
        flex-wrap: wrap;
    }
    .list .item{
        width: 100%;
        height: 160px;
        box-sizing: border-box;
        border-bottom: 1px solid  #DDDDDD;
        padding-right: 2px;

        display: flex;
        align-items: center;
    }
    .list .item .name{
        width: 100%;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
    }
    .list .item .btn{
        flex-shrink: 0;
        width: 55px;
        height: 55px;

        background: url("../assets/fasong.png");
        background-size: contain;

    }
    .close{
        width: 130px;
        height: 130px;
        background: url("../assets/guanbi.png");
        position: absolute;
        top: -50px;
        right: -50px;
    }
    .fill{
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fill .wrapper{
        width: 760px;
        height: 500px;
        background: rgba(255,255,255,0.5);
        padding: 20px;
        border-radius: 35px;
        box-sizing: border-box;
        position: relative;
    }
    .fill .fill-content{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 29px 125px 0px rgba(51,94,204,0.52);
        border-radius: 30px;

        padding: 60px;
        box-sizing: border-box;
    }
    .fill .title{
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 46px;
    }
    .fill .input-group{
        width: 100%;
        height: 101px;
        background: #FFFFFF;
        border: 2px solid #0087D5;
        box-shadow: 0px 6px 6px 0px rgba(51,94,204,0);
        border-radius: 10px;
        margin-top: 40px;
        padding: 0 25px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
    .fill .input-group::before{
        flex-shrink: 0;
        display: block;
        content: '';
        width: 56px;
        height: 56px;
        background: url("../assets/youxiang.png");
        background-size: contain;
        margin-right: 20px;
    }
    .fill .input-group input{
        width: 100%;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0451C5;
        line-height: 48px;

        outline:none;
        border: 0;
    }
    .fill .input-group input::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #0451C5;
    }
    .fill .btn{
        width: 287px;
        height: 91px;
        background: #0451C5;
        border-radius: 20px;

        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 91px;
        text-align: center;

        margin: 60px auto 0;
    }
    .fill .fill-close{
        width: 162px;
        height: 162px;
        background: url("../assets/guanbi_2.png");
        position: absolute;
        right: -60px;
        top: -60px;
    }
</style>
