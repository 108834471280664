import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";

axios.defaults.withcredentials = true;
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/api/';
}

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '/api/';
}

axios.interceptors.request.use(function (config) {
    console.log(config)
    // 配置config
    config.headers.Accept = 'application/json'
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
        config.headers['TOKEN'] = user.token;
    }
    return config
})


// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    console.log(response);
    if(response.data.code == -1){
        router.push("/login");
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    // notification['error']({
    //     message: '操作失败',
    //     description: '请刷新网页重试，或联系管理员',
    //     duration: 5,
    // });
    return Promise.reject(error);
});

createApp(App).use(router).mount('#app')
