<template>
  <div class="page">
      <div class="left">
          <div class="logo"></div>
          <div class="menu">
              <div class="item">
                  <div class="icon product"></div>
                  <div class="name">产品系列</div>
              </div>
              <div class="item" @click="goAbout">
                  <div class="icon about"></div>
                  <div class="name">关于我们</div>
              </div>
              <div class="item" @click="onSend">
                  <div class="icon email"></div>
                  <div class="name">发送资料</div>
              </div>
              <div class="item" @click="goWelcome">
                  <div class="icon welcome"></div>
                  <div class="name">返回欢迎页</div>
              </div>
              <div class="item" @click="goSet">
                  <div class="icon set"></div>
                  <div class="name">个人设置</div>
              </div>
          </div>
      </div>
      <div class="right">
        <div class="list">
            <div class="item" @click="goProduct(item.id)" v-for="(item, index) in data" v-bind:key="index">
                <div class="icon">
                    <img :src="item.icon">
                </div>
                <div class="name">{{ item.name }}</div>
            </div>
        </div>
      </div>
      <Send v-if="showSend" @close="showSend = false"/>
  </div>
</template>

<script setup>

import {useRoute, useRouter} from 'vue-router'
import Send from '@/components/Send'
import {onBeforeMount, ref} from 'vue'
import axios from 'axios'
const showSend = ref(useRoute().query.showSend);
const onSend = ()=>{
    showSend.value = true;
}

const router = useRouter()
const goProduct = (id) => {
    router.push({path:"/product", query:{
        id,
    }})
}
const goWelcome = () => {
    router.push("/welcome")
}
const goAbout = () => {
    router.push("/about")
}
const goSet = () => {
    router.push("/set")
}

const data = ref({});
const syncData = () => {
    axios.get("/product/type/list").then(({data:res}) => {
        if (res.code == 200) {
            data.value = res.data;
        } else if(res.code != -1) {
            alert(res.msg);
        }
    })
}

onBeforeMount(() => {
    syncData();
})

</script>

<style scoped>
.page{
    display: flex;
}
.left{
    flex-shrink: 0;

    overflow: hidden;
    height: 100%;
    width: 390px;
    padding-right: 66px;
    box-sizing: border-box;

    background: url("../assets/cebg.jpg") no-repeat;
    background-size: cover;
}
.logo{
    width: 235px;
    height: 93px;
    background: url("../assets/logo_2.png");
    background-size: 100%;

    margin: 66px auto 0;
}
.menu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    padding: 160px 0;
    box-sizing: border-box;
}
.menu .item{
    width: 100%;
}
.menu .item .icon{
    width: 90px;
    height: 90px;
    background-size: contain;
    background-position: center;

    margin: auto;
}
.menu .item .icon.product{
    background-image: url("../assets/nav_1.png");
}
.menu .item .icon.about{
    background-image: url("../assets/nav_2.png");
}
.menu .item .icon.email{
    background-image: url("../assets/nav_3.png");
}
.menu .item .icon.welcome{
    background-image: url("../assets/nav_4.png");
}
.menu .item .icon.set{
    background-image: url("../assets/nav_5.png");
}
.menu .item .name{
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 35px;
    text-align: center;

    margin-top: 28px;
}
.right{
    width: 100%;
    height: 100%;
    background-image: url("../assets/bg_2.jpg");
    background-size: cover;
    border-left: 2px solid rgba(73,201,255,0.48);

    border-top-left-radius: 66px;
    border-bottom-left-radius: 66px;

    margin-left: -66px;
}

.list{
    display: flex;
    margin: 600px auto 0;
    justify-content: center;
}
.list .item{
    margin: 0 40px;
}
.list .item .icon{
    width: 408px;
    height: 408px;
    background-size: contain;
}
.list .item .icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.list .item:active .icon{
    background-image: url("../assets/pass_1.png");
}
.list .item .icon.no2{
    background-image: url("../assets/nom_2.png");
}
.list .item:active .icon.no2{
    background-image: url("../assets/pass_2.png");
}
.list .item .icon.no3{
    background-image: url("../assets/nom_3.png");
}
.list .item:active .icon.no3{
    background-image: url("../assets/pass_3.png");
}
.list .item .icon.no4{
    background-image: url("../assets/nom_4.png");
}
.list .item:active .icon.no4{
    background-image: url("../assets/pass_4.png");
}
.list .item .icon.no5{
    background-image: url("../assets/nom_5.png");
}
.list .item:active .icon.no5{
    background-image: url("../assets/pass_5.png");
}
.list .item .name{
    font-size: 50px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #0451C5;
    line-height: 50px;
    text-align: center;
    margin-top: 30px;
}
</style>
